export enum LoanStatus {
  Submitted = 1,
  Processing = 2,
  NeedsInput = 3,
  Prequalified = 4,
  OfferSent = 5,
  PrequalOfferAccepted = 6,
  PrequalOfferRejected = 7,
  Approved = 8,
  DocumentsSent = 9,
  ConditionsMet = 10,
  Withdrawn = 11,
  PrequalDenied = 12,
  BankDenied = 13
}

export enum LoanType {
  PurchaseFixedAsset = 1,
  RefinanceDebt = 2,
  OperatingLoan = 3,
  DealerPurchase = 4
}

export enum FixedAssetType {
  Equipment = 1,
  Livestock = 2,
  RealEstate = 3,
}

export enum CollateralEquityTypes {
  CashDown = 1,
  Trade = 2,
  OtherCollateral = 3,
}

export enum LivestockCommodityType {
  Beef = 1,
  Poultry = 2,
  Pork = 3,
  Other = 4,
}

export enum CropCommodityType {
  Corn = 1,
  Hay = 2,
  Pasture = 3,
  Soybeans = 4,
  Wheat = 5,
  Other = 6,
}

export enum GrossRevenueType {
  Projected = 1,
  Prior = 2,
}

export enum ApplicantType {
  Person = 1,
  Entity = 2,
}

export enum ScorecardLevel {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
}

export enum LoanAction {
  Prequalify = 1,
  RequestInput = 2,
  EditOffer = 3,
  CounterOffer = 4,
  Approve = 5,
  DocumentsSent = 6,
  ConditionsMet = 7,
  Withdraw = 8,
  PrequalDeny = 9,
  BankDeny = 10,
}

export enum LendingLoanType {
  InstallmentLoanSingleAdvance = 1,
  SinglePaySingleAdvance = 2,
  RevolvingLOCMultipleAdvance = 3,
  ClosedEndLOCMultipleAdvance = 4,
}

export enum InterestType {
  Variable = 1,
  FixedRate = 2,
}

export enum PeriodicRateIncrease {
  Immediate = 1,
  ThirtyDays = 2,
  SixtyDays = 3,
  NinetyDays = 4,
  OneHundredTwentyDays = 5,
  OneHundredEightyDays = 6,
  OneMonth = 7,
  ThreeMonths = 8,
  SixMonths = 9,
  TwelveMonths = 10,
  EighteenMonths = 11,
  TwentyFourMonths = 12,
  ThirtySixMonths = 13,
  FortyEightMonths = 14,
  SixtyMonths = 15,
}

export enum SecurityRoleEnum {
  Admin = 1,
  LoanOfficer = 2,
  Manager = 3,
  DealerSalesman = 4,
}

export enum AgriculturalProductMeasurers {
  None = 0,
  Acres = 1,
  Bale = 2,
  Bushels = 3,
  CwtPer100Pounds = 4,
  Flat = 5,
  FourFiveBushel = 6,
  Gallon = 7,
  Hogshead = 8,
  Lug = 9,
  Other = 10,
  PerAcre = 11,
  PerBoardFoot = 12,
  PerHead = 13,
  Pound = 14,
  Sacks = 15,
  ThreeFourBushel = 16,
  Tons = 17,
}

export enum DocumentRequestStatus {
  Fulfilled = 1,
  Unfulfilled = 2,
}

export enum DocumentType {
  TaxReturn = 1,
  BalanceSheet = 2,
  CashFlow = 3,
  Other = 4,
  RegB = 5,
  PhotoOfVin = 6,
  PurchaseOrderForm = 7,
  PurchasePhoto = 8
}

export const DocumentTypeMapper = {
  [Number(DocumentType.TaxReturn)]: 'Tax Return',
  [Number(DocumentType.BalanceSheet)]: 'Balance Sheet',
  [Number(DocumentType.CashFlow)]: 'Cash Flow',
  [Number(DocumentType.Other)]: 'Other',
  [Number(DocumentType.RegB)]: 'Regulation B',
  [Number(DocumentType.PhotoOfVin)]: 'Photo Of Vin/Serial',
  [Number(DocumentType.PurchaseOrderForm)]: 'Purchase Order Form',
  [Number(DocumentType.PurchasePhoto)]: 'Purchase Photo',
};

export const DocumentStringTypeMapper = {
  [DocumentType.TaxReturn]: 'Tax Return',
  [DocumentType.BalanceSheet]: 'Balance Sheet',
  [DocumentType.CashFlow]: 'Cash Flow',
  [DocumentType.Other]: 'Other',
  [DocumentType.RegB]: 'Regulation B',
  [DocumentType.PhotoOfVin]: 'Transaction Details Form',
  [DocumentType.PurchaseOrderForm]: 'Purchase Order Form',
  [DocumentType.PurchasePhoto]: 'Purchase Photo',
};

export enum BalanceSheetAssetCategories {
  CashAndEquivalents = 1,
  MarketableBondsAndSecurities = 2,
  AccountsReceivable = 3,
  CropInventory = 4,
  GrowingCrops = 5,
  BreedingStock = 6,
  MarketLivestockOrPoultry = 7,
  SpecialtyLivestockOrPoultry = 8,
  PrepaidExpensesAndSupplies = 9,
  NotesReceivable = 10,
  NotReadilyMarketableBondsAndSecurities = 11,
  BuildingAndImprovements = 12,
  RealEstateLand = 13,
  Personal = 14,
  MachineryAndEquipment = 15,
  FarmVehicles = 16,
  PersonalVehicles = 17,
  Other = 18
}

export enum NAICSGroup {
  CropInventory = 1,
  GrowingCrops = 2,
  BreedingStock = 3,
  MarketLivestockOrPoultry = 4,
  SpecialtyLivestockOrPoultry = 5
}

export enum EquipmentCondition {
  Excellent = 1,
  Good = 2,
  Average = 3,
  Fair = 4,
  Poor = 5
}

export enum YesNoValues {
  Yes = 1,
  No = 2
}

export enum BalanceSheetLiabilityCategories {
  AccountsPayable = 1,
  IncomeTaxesPayable = 2,
  RealEstateTaxesPayable = 3,
  NotesPayable = 4,
  OtherLiabilities = 5
}

export enum NotesPayableLoanPurpose {
  Equipment = 1,
  Operating = 2,
}

export enum IdDocumentSde {
  Front = 1,
  Back = 2
}

export enum Subdomain {
  Admin = 1,
  Dealer = 2
}

export enum LoanTermType {
  Years = 1,
  Months = 2 // We will default and only allow years for now, but we store the amount in months. Eventually we may need to allow them to choose
}